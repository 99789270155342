<template>
  <div class="main_box">
    <div class="top">
      <!-- <p class="top_thread" @click="showThread">主题 <span>({{ post_num }})</span></p>
      <p class="top_archive" @click="showArchive">档案</p> -->
    </div>
    <div v-if="lists.length > 0" class="data_lists">
      <div v-for="(item) in lists" :key="item.index" class="data">
        <div v-show="item.pic.length > 0" class="d_thumb">
          <img :src="item.pic" alt="" @click="threadDetail(item)">
        </div>
        <div class="d_cnt">
          <div class="cnt_title" @click="threadDetail(item)">
            <h3>{{ item.title }}</h3>
          </div>
          <div class="cnt_info">
            <span>
              {{ item.username }} 发表于 {{ item.create_time | formatDate }}
            </span>
            <div class="view">
              <img src="@/assets/image/thread_eye.png" alt="">
              <span>{{ item.view_num }}人浏览</span>
            </div>
            <div class="commit">
              <img src="@/assets/image/pinglun.png" alt="">
              <span>{{ item.reply_num }}人跟帖</span>
            </div>
          </div>
          <div class="cnt_content">
            <p>{{ item.content }}</p>
          </div>
          <div class="cnt_last">
            <p>
              最新回复：
              <span class="cnt_l_name" @click="clickUser(item)">{{ item.last_username }}</span>
              <span>
                {{ item.last_time | formatDate }}
              </span>
              <img v-if="item.from_sys===0" src="@/assets/image/diannao.gif" title="电脑版">
              <img v-else-if="item.from_sys===1" src="@/assets/image/qz_tzios.gif" title="苹果手机APP">
              <img v-else src="@/assets/image/qz_tzandroid.gif" title="安卓手机APP">
            </p>
          </div>
        </div>
      </div>
      <el-pagination
        v-show="post_num>params.limit"
        class="page"
        background
        :total="post_num"
        layout="prev, pager, next"
        :page-size="params.limit"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 暂无数据 -->
    <div v-else class="noDatasBox">
      <img src="@/assets/image/kong.png" alt="">
      <p>您还没有发布主题哦~</p>
    </div>
  </div>
</template>

<script>
import { homepageThread } from '@/api/center'
import tool from '@/utils/tools.js'
import Cookies from 'js-cookie'

export default {
  filters: {
    formatDate(time) {
      const t = time * 1000
      return tool.formatDate(t, 'yyyy-MM-dd')
    }
  },
  data() {
    return {
      toUid: this.$route.query.u ? this.$route.query.u : '',
      post_num: 0,
      lists: [],
      detail_url: '',
      params: {
        uid: '',
        page: 1,
        limit: 5
      }
    }
  },
  created() {
    var uid = Cookies.get('uid')
    if (!uid) {
      this.$router.push({
        path: '/login'
      })
      return
    }
    this.getUserPost()
  },
  methods: {
    // 获取用户发的主题
    getUserPost() {
      this.params.uid = this.toUid
      if (this.$route.query.is_all) {
        this.params.is_all = this.$route.query.is_all
      }
      console.log(this.params)
      homepageThread(this.params).then(res => {
        const result = res.result
        this.post_num = result.count
        this.lists = result.list
      }).catch(err => {
        console.log(err)
      })
    },
    threadDetail(item) {
      // 帖子详情
      window.open(item.detail_url, '_blank')
    },
    // 分页
    handleCurrentChange(val) {
      this.params.page = val
      this.getUserPost()
    },
    // 其他用户主页
    clickUser(val) {
      console.log(val.last_uid)
    }
  }
}
</script>

<style lang="scss" scoped>
.main_box {
  text-align: center;
  width: 100%;
  min-height: 500px;
  background-color: #fff;
  text-align: center;
}
.data_lists {
  width: 100%;
  background-color: #fff;
  margin: 0  auto;
  padding: 20px 0px;
  text-align: center;
  .data {
    text-align: left;
    width: 100%;
    float: left;
    display: flex;
    padding: 16px 10px;
    border-bottom: 1px solid #ddd;
    .d_thumb {
      width: 164px;
      height: 102px;
      overflow: hidden;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .d_cnt {
      position: relative;
      display: block;
      width: 100%;
      h3 {
        color: #444;
        font-weight: bold;
        font-size: 24px;
        max-width: 600px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      .cnt_info {
        width: 100%;
        height: 22px;
        color: #999999;
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
        // background-color: greenyellow;
        .view {
          display: inline-block;
          margin-left: 20px;
          img {
            vertical-align: baseline;
            margin-right: 2px;
            width: 16px;
            height: 10px;
          }
        }
        .commit {
          display: inline-block;
          margin-left: 20px;
          img {
            margin-top: -1px;
            margin-right: 2px;
          }
        }
      }
      .cnt_content {
        background-color: fff;
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        color: #666;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .cnt_last {
      margin-top: 10px;
      float: right;
      color: #999;
      height: 25px;
      line-height: 25px;
      font-size: 14px;
      img {
        margin-top: -2px;
        display: inline-block;
      }
      .cnt_l_name {
        cursor: pointer;
      }
    }
  }
}
.page {
  display: inline-block;
  padding: 30px 0 10px 0;
  margin: 0 auto;
}
.noDatasBox {
  position: relative;
  width: 100%;
  min-height: 500px;
  img{
    display: block;
    margin: 0 auto;
    margin-top: 140px;
    width: 140px;
    height: 140px;
  }
  p {
    margin-top: 20px;
    text-align: center;
    line-height: 20px;
  }
}
</style>
